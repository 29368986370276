import { Box, styled, Typography } from '@mui/material';
import pxToEm from 'utils/px-to-em';
interface DataDisplayProps {
  value: string | number | undefined;
  statusTextColor?: string;
  textColor?: string;
  label?: string;
  unit?: string;
  goal?: number | string;
}

export const BoldText = styled('span')({
  fontWeight: 700,
});

export const H3 = styled(Typography)(({ theme }) => ({
  fontSize: pxToEm(40),
  lineHeight: theme.spacing(6),
  [theme.breakpoints.between('newLg', 'newXl')]: {
    fontSize: pxToEm(36),
  },
}));

const DataDisplay = ({
  goal,
  label,
  unit,
  value,
  statusTextColor,
  textColor,
}: DataDisplayProps) => {
  if (!value) {
    return null;
  }

  return (
    <Box flexGrow={1}>
      <Typography color={textColor} variant="body1">
        {label}
      </Typography>
      <Box display="flex">
        <H3 variant="h3" color={statusTextColor}>
          {value}
        </H3>
        <Typography variant="body2" pl={1} pt={1}>
          {unit}
        </Typography>
      </Box>
      {goal && (
        <Typography variant="body1" color="primary">
          GOAL/<BoldText>{goal}</BoldText>
        </Typography>
      )}
    </Box>
  );
};

export default DataDisplay;
