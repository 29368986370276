import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 80, height: 80 };

export const wristTemperature: IconifyJSON = {
  prefix: 'wrist-temperature',
  icons: {
    high: {
      ...defaultSizes,
      body: '<path d="M40 71.6667C35.8378 71.6667 32.2972 70.2072 29.3783 67.2883C26.4594 64.3694 25 60.8289 25 56.6667C25 54.1283 25.5886 51.7758 26.7658 49.6092C27.9436 47.4425 29.5772 45.6497 31.6667 44.2308V16.6667C31.6667 14.3378 32.4733 12.3667 34.0867 10.7533C35.7 9.14 37.6711 8.33334 40 8.33334C42.3289 8.33334 44.3 9.14 45.9133 10.7533C47.5267 12.3667 48.3333 14.3378 48.3333 16.6667V44.2308C50.4228 45.6497 52.0564 47.4425 53.2342 49.6092C54.4114 51.7758 55 54.1283 55 56.6667C55 60.8289 53.5406 64.3694 50.6217 67.2883C47.7028 70.2072 44.1622 71.6667 40 71.6667ZM36.6667 37.3075H43.3333V33.1408H40V30.1925H43.3333V23.1408H40V20.1925H43.3333V16.6667C43.3333 15.7222 43.0139 14.9306 42.375 14.2917C41.7361 13.6528 40.9444 13.3333 40 13.3333C39.0556 13.3333 38.2639 13.6528 37.625 14.2917C36.9861 14.9306 36.6667 15.7222 36.6667 16.6667V37.3075Z" fill="currentColor"/>',
    },
    low: {
      ...defaultSizes,
      body: '<path d="M40 71.6667C35.8378 71.6667 32.2972 70.2072 29.3783 67.2883C26.4594 64.3694 25 60.8289 25 56.6667C25 54.1283 25.5886 51.7758 26.7658 49.6092C27.9436 47.4425 29.5772 45.6497 31.6667 44.2308V16.6667C31.6667 14.3378 32.4733 12.3667 34.0867 10.7533C35.7 9.14 37.6711 8.33334 40 8.33334C42.3289 8.33334 44.3 9.14 45.9133 10.7533C47.5267 12.3667 48.3333 14.3378 48.3333 16.6667V44.2308C50.4228 45.6497 52.0564 47.4425 53.2342 49.6092C54.4114 51.7758 55 54.1283 55 56.6667C55 60.8289 53.5406 64.3694 50.6217 67.2883C47.7028 70.2072 44.1622 71.6667 40 71.6667ZM36.6667 37.3075H43.3333V33.1408H40V30.1925H43.3333V23.1408H40V20.1925H43.3333V16.6667C43.3333 15.7222 43.0139 14.9306 42.375 14.2917C41.7361 13.6528 40.9444 13.3333 40 13.3333C39.0556 13.3333 38.2639 13.6528 37.625 14.2917C36.9861 14.9306 36.6667 15.7222 36.6667 16.6667V37.3075Z" fill="currentColor"/>',
    },
    normal: {
      ...defaultSizes,
      body: '<path d="M40 71.6667C35.8378 71.6667 32.2972 70.2072 29.3783 67.2883C26.4594 64.3694 25 60.8289 25 56.6667C25 54.1283 25.5886 51.7758 26.7658 49.6092C27.9436 47.4425 29.5772 45.6497 31.6667 44.2308V16.6667C31.6667 14.3378 32.4733 12.3667 34.0867 10.7533C35.7 9.13999 37.6711 8.33333 40 8.33333C42.3289 8.33333 44.3 9.13999 45.9133 10.7533C47.5267 12.3667 48.3333 14.3378 48.3333 16.6667V44.2308C50.4228 45.6497 52.0564 47.4425 53.2342 49.6092C54.4114 51.7758 55 54.1283 55 56.6667C55 60.8289 53.5406 64.3694 50.6217 67.2883C47.7028 70.2072 44.1622 71.6667 40 71.6667ZM36.6667 37.3075H43.3333V33.1408H40V30.1925H43.3333V23.1408H40V20.1925H43.3333V16.6667C43.3333 15.7222 43.0139 14.9306 42.375 14.2917C41.7361 13.6528 40.9444 13.3333 40 13.3333C39.0556 13.3333 38.2639 13.6528 37.625 14.2917C36.9861 14.9306 36.6667 15.7222 36.6667 16.6667V37.3075Z" fill="currentColor"/>',
    },
    noData: {
      ...defaultSizes,
      body: '<path d="M40 71.6667C35.8378 71.6667 32.2972 70.2072 29.3783 67.2883C26.4594 64.3695 25 60.8289 25 56.6667C25 54.1283 25.5886 51.7758 26.7658 49.6092C27.9436 47.4425 29.5772 45.6497 31.6667 44.2308V16.6667C31.6667 14.3378 32.4733 12.3667 34.0867 10.7533C35.7 9.14001 37.6711 8.33334 40 8.33334C42.3289 8.33334 44.3 9.14001 45.9133 10.7533C47.5267 12.3667 48.3333 14.3378 48.3333 16.6667V44.2308C50.4228 45.6497 52.0564 47.4425 53.2342 49.6092C54.4114 51.7758 55 54.1283 55 56.6667C55 60.8289 53.5406 64.3695 50.6217 67.2883C47.7028 70.2072 44.1622 71.6667 40 71.6667ZM36.6667 37.3075H43.3333V33.1408H40V30.1925H43.3333V23.1408H40V20.1925H43.3333V16.6667C43.3333 15.7222 43.0139 14.9306 42.375 14.2917C41.7361 13.6528 40.9444 13.3333 40 13.3333C39.0556 13.3333 38.2639 13.6528 37.625 14.2917C36.9861 14.9306 36.6667 15.7222 36.6667 16.6667V37.3075Z" fill="currentColor"/>',
    },
    disabled: {
      ...defaultSizes,
      body: '<path d="M40 71.6667C35.8378 71.6667 32.2972 70.2073 29.3783 67.2884C26.4594 64.3695 25 60.8289 25 56.6667C25 54.1284 25.5886 51.7759 26.7658 49.6092C27.9436 47.4425 29.5772 45.6498 31.6667 44.2309V16.6667C31.6667 14.3378 32.4733 12.3667 34.0867 10.7534C35.7 9.14004 37.6711 8.33337 40 8.33337C42.3289 8.33337 44.3 9.14004 45.9133 10.7534C47.5267 12.3667 48.3333 14.3378 48.3333 16.6667V44.2309C50.4228 45.6498 52.0564 47.4425 53.2342 49.6092C54.4114 51.7759 55 54.1284 55 56.6667C55 60.8289 53.5406 64.3695 50.6217 67.2884C47.7028 70.2073 44.1622 71.6667 40 71.6667ZM36.6667 37.3075H43.3333V33.1409H40V30.1925H43.3333V23.1409H40V20.1925H43.3333V16.6667C43.3333 15.7223 43.0139 14.9306 42.375 14.2917C41.7361 13.6528 40.9444 13.3334 40 13.3334C39.0556 13.3334 38.2639 13.6528 37.625 14.2917C36.9861 14.9306 36.6667 15.7223 36.6667 16.6667V37.3075Z" fill="currentColor"/>',
    },
  },
};
