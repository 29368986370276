import { ProdEnv } from 'pages/Login/components/EnvChoose';
import { BCLocalStorage } from './storage';

export const changeEnvironment = (env: ProdEnv, email: string) => {
  const isHipaaNow = import.meta.env.REACT_APP_NODE_ENV.includes('hipaa');

  if (env === 'hipaa' && !isHipaaNow) {
    if (!window.hasOwnProperty('cordova')) {
      window.location.href = `${
        import.meta.env.REACT_APP_DOMAIN_ALTERNATE
      }/login?email=${email}&redirected="true"`;
    } else {
      BCLocalStorage.hipaa = true;
    }
    return;
  }

  if (env === 'personal' && isHipaaNow) {
    if (!window.hasOwnProperty('cordova')) {
      window.location.href = `${
        import.meta.env.REACT_APP_DOMAIN_ALTERNATE
      }/login?email=${email}&redirected="true"`;
    } else {
      BCLocalStorage.hipaa = false;
    }
  }
};
