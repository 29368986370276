import { FilterItemProps } from 'components/Filters/components';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setFilters,
  resetFilters as reduxResetFilters,
} from 'store/reducers/filters/filters.slice';
import {
  FilterAppleWatchBatteryStatus,
  FilterAppleWatchBloodOxygenStatus,
  FilterAppleWatchDeviceStatus,
  FilterAppleWatchFallDetectionStatus,
  FilterAppleWatchHeartRateStatus,
  FilterAppleWatchHeartRhythmStatus,
  FilterAppleWatchSafeZoneStatus,
  FiltersAppleWatchKeys,
} from 'store/reducers/filters/types';
import { useFiltersQueryParams } from './use-filters-query-params';

export const FilterTabIndex = {
  APPLE_WATCH: 0,
  PATIENT_DETAILS: 1,
  SURVEYS: 2,
} as const;

export type FilterTabIndex =
  (typeof FilterTabIndex)[keyof typeof FilterTabIndex];

const DEFAULT_VISIBLE: FiltersAppleWatchKeys[] = [
  'device',
  'battery',
  'safeZone',
  'fallDetection',
  'heartRate',
  'heartRhythm',
  'bloodOxygen',
];

export const useFilters = (isSleepVisible?: boolean) => {
  const filters = useAppSelector((state) => state.filters.filters);
  const areFiltersModified = useAppSelector(
    (state) => state.filters.areFiltersModified,
  );
  const dispatch = useAppDispatch();

  const {
    appleWatchFilters: queryAppleWatchFilters,
    rewriteAppleWatchFilters: rewriteQueryAppleWatchFilters,
    visibleColumns,
  } = useFiltersQueryParams();

  const resetFilters = () => {
    dispatch(reduxResetFilters());
  };

  useEffect(() => {
    const appleWatch = { ...queryAppleWatchFilters };

    if (visibleColumns.length === 0) {
      const newAppleWatchFilters = (
        isSleepVisible
          ? ([...DEFAULT_VISIBLE, 'sleepTime'] as FiltersAppleWatchKeys[])
          : ([
              ...DEFAULT_VISIBLE,
              'helpRequested',
              'seizureDetected',
            ] as FiltersAppleWatchKeys[])
      ).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...appleWatch[key],
            isInvisible: false,
          },
        }),
        {},
      );

      Object.assign(appleWatch, newAppleWatchFilters);
    }

    dispatch(
      setFilters({
        ...filters,
        appleWatch,
      }),
    );
  }, []);

  useEffect(() => {
    rewriteQueryAppleWatchFilters(filters.appleWatch);
  }, [filters.appleWatch]);

  const renderAppleWatchItemSharedProps = (key: FiltersAppleWatchKeys) => ({
    key,
    activeChips: filters.appleWatch[key].selected,
    isInvisible: filters.appleWatch[key].isInvisible,
    exclusiveFor: filters.appleWatch[key].exclusiveFor,
    onVisibilityToggle: () =>
      dispatch(
        setFilters({
          ...filters,
          appleWatch: {
            ...filters.appleWatch,
            [key]: {
              ...filters.appleWatch[key],
              isInvisible: !filters.appleWatch[key].isInvisible,
            },
          },
        }),
      ),
    onChipToggle: (value: string) =>
      dispatch(
        setFilters({
          ...filters,
          appleWatch: {
            ...filters.appleWatch,
            [key]: {
              ...filters.appleWatch[key],
              selected: (filters.appleWatch[key].selected as string[]).includes(
                value,
              )
                ? [...filters.appleWatch[key].selected].filter(
                    (item) => item !== value,
                  )
                : [...filters.appleWatch[key].selected, value],
            },
          },
        }),
      ),
  });

  const appleWatchFiltersProps: FilterItemProps[] = [
    {
      ...renderAppleWatchItemSharedProps('device'),
      name: 'Device',
      chips: [
        {
          displayName: 'Disconnected',
          value: FilterAppleWatchDeviceStatus.DISCONNECTED,
          color: 'error',
        },
        {
          displayName: 'Misconfigured',
          value: FilterAppleWatchDeviceStatus.MISCONFIGURED,
          color: 'error',
        },
        {
          displayName: 'Locked',
          value: FilterAppleWatchDeviceStatus.LOCKED,
          color: 'error',
        },
        {
          displayName: 'Not worn',
          value: FilterAppleWatchDeviceStatus.NOT_WORN,
          color: 'error',
        },
        {
          displayName: 'OK',
          value: FilterAppleWatchDeviceStatus.OK,
          color: 'secondary',
        },
      ],
    },
    {
      ...renderAppleWatchItemSharedProps('battery'),
      name: 'Battery Level',
      chips: [
        {
          displayName: 'Charge!',
          value: FilterAppleWatchBatteryStatus.CRITICAL,
          color: 'error',
        },
        {
          displayName: 'Low',
          value: FilterAppleWatchBatteryStatus.LOW,
          color: 'error',
        },
        {
          displayName: 'Good',
          value: FilterAppleWatchBatteryStatus.GOOD,
          color: 'secondary',
        },
        {
          displayName: 'Charging',
          value: FilterAppleWatchBatteryStatus.CHARGING,
          color: 'secondary',
        },
      ],
    },
    {
      ...renderAppleWatchItemSharedProps('safeZone'),
      name: 'Safe Zone',
      chips: [
        {
          displayName: 'Not Set',
          value: FilterAppleWatchSafeZoneStatus.NOT_SET,
          color: 'error',
        },
        {
          displayName: 'Outside',
          value: FilterAppleWatchSafeZoneStatus.OUTSIDE,
          color: 'error',
        },
        {
          displayName: 'Inside',
          value: FilterAppleWatchSafeZoneStatus.INSIDE,
          color: 'secondary',
        },
        {
          displayName: 'Disabled',
          value: FilterAppleWatchSafeZoneStatus.DISABLED,
        },
      ],
    },
    {
      ...renderAppleWatchItemSharedProps('fallDetection'),
      name: 'Fall Detection',
      chips: [
        {
          displayName: 'Fall detected',
          value: FilterAppleWatchFallDetectionStatus.DETECTED,
          color: 'error',
        },
        {
          displayName: 'OK',
          value: FilterAppleWatchFallDetectionStatus.OK,
          color: 'secondary',
        },
      ],
    },
    {
      ...renderAppleWatchItemSharedProps('heartRate'),
      name: 'Heart Rate',
      chips: [
        {
          displayName: 'High',
          value: FilterAppleWatchHeartRateStatus.HIGH,
          color: 'error',
        },
        {
          displayName: 'Low',
          value: FilterAppleWatchHeartRateStatus.LOW,
          color: 'error',
        },
        // {
        //   displayName: 'Good',
        //   value: FilterAppleWatchHeartRateStatus.GOOD,
        //   color: 'secondary',
        // },
        {
          displayName: 'Disabled',
          value: FilterAppleWatchHeartRateStatus.DISABLED,
        },
      ],
    },
    {
      ...renderAppleWatchItemSharedProps('bloodOxygen'),
      name: 'Blood Oxygen',
      chips: [
        {
          displayName: 'Low',
          value: FilterAppleWatchBloodOxygenStatus.LOW,
          color: 'error',
        },
        {
          displayName: 'Normal',
          value: FilterAppleWatchBloodOxygenStatus.NORMAL,
          color: 'secondary',
        },
        {
          displayName: 'Disabled',
          value: FilterAppleWatchBloodOxygenStatus.DISABLED,
        },
      ],
    },
    {
      ...renderAppleWatchItemSharedProps('heartRhythm'),
      name: 'Heart Rhythm',
      chips: [
        {
          displayName: 'Irregular',
          value: FilterAppleWatchHeartRhythmStatus.IRREGULAR,
          color: 'error',
        },
      ],
    },
    {
      ...renderAppleWatchItemSharedProps('sleepTime'),
      name: 'Sleep Time',
      chips: [],
    },
    {
      ...renderAppleWatchItemSharedProps('helpRequested'),
      name: 'Help Request',
      chips: [
        {
          displayName: 'Help request',
          value: FilterAppleWatchFallDetectionStatus.DETECTED,
          color: 'error',
        },
        {
          displayName: 'OK',
          value: FilterAppleWatchFallDetectionStatus.OK,
          color: 'secondary',
        },
      ],
    },
    {
      ...renderAppleWatchItemSharedProps('seizureDetected'),
      name: 'Seizure Detected',
      chips: [
        {
          displayName: 'Seizure detected',
          value: FilterAppleWatchFallDetectionStatus.DETECTED,
          color: 'error',
        },
        {
          displayName: 'OK',
          value: FilterAppleWatchFallDetectionStatus.OK,
          color: 'secondary',
        },
      ],
    },
    // Commented because this filter is temporarly unused:
    // {
    //   ...renderAppleWatchItemSharedProps('respiratoryRate'),
    //   name: 'Respiratory Rate',
    //   chips: [
    //     {
    //       displayName: 'Low',
    //       value: FilterAppleWatchRespiratoryRateStatus.LOW,
    //       color: 'error',
    //     },
    //     {
    //       displayName: 'Normal',
    //       value: FilterAppleWatchRespiratoryRateStatus.NORMAL,
    //       color: 'secondary',
    //     },
    //     {
    //       displayName: 'Disabled',
    //       value: FilterAppleWatchRespiratoryRateStatus.DISABLED,
    //     },
    //   ],
    // },
  ];

  return {
    isUnset: !areFiltersModified,
    filters,
    setFilters,
    appleWatchFiltersProps,
    resetFilters,
  };
};
