import { Day } from 'utils/weekdays';
import {
  AlerterRecipientManager,
  AlerterSummary,
  PatternType,
  RawAlerterSummary,
  RawAlerterTrigger,
  SourceStreamType,
  SourceType,
} from '.';

export type RawReminderSummary = RawAlerterSummary;
export type ReminderSummary = AlerterSummary;

export interface GetRemindersResponse {
  results: RawReminderSummary[];
}

export interface CreateReminderParams {
  title: string;
  message: string;
  alerterRecipientManager?: Omit<
    AlerterRecipientManager,
    'sets' | 'id' | 'alerters'
  > & {
    sets: { priority: number; recipients: { patternType: PatternType }[] }[];
  };
  alerterRecipientManagerId?: number;
  streamType?: SourceStreamType;
  sourceId?: number;
  sourceType?: SourceType;
}

export interface RawReminderSerializedSchedule {
  start_time: number;
  days_of_week: Day[];
}

export interface RawScheduleRule {
  id: number;
  alerter_trigger_id: number;
  enabled: boolean;
  title: string | null;
  serialized_schedule: RawReminderSerializedSchedule;
}

export interface ReminderSerializedSchedule {
  startTime: number;
  days: Day[];
}

export interface ScheduleRule {
  id: number;
  alerterTriggerId: number;
  enabled: boolean;
  title: string | null;
  serializedSchedule: ReminderSerializedSchedule;
}

export interface RawReminderTrigger extends RawAlerterTrigger {
  schedule_rules: RawScheduleRule[];
}

export interface GetReminderTriggerResponse {
  result: RawReminderTrigger;
}

export interface UpdateReminderParams {
  id: number;
  message?: string;
  title?: string;
  enabled?: boolean;
  messageImage?: string | null;
  messageImageUrl?: string | null;
}

export interface ReminderEditScheduleRuleParams
  extends Partial<Omit<ScheduleRule, 'id' | ' alerterTriggerId'>> {
  ruleId: number;
}
