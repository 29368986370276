import axios from 'axios';
import { IGetCsvCustomRecordsRequest } from 'models/dashboard.model';
import { toast } from 'react-toastify';

export const getPatientDataCsv = async ({
  total,
  token,
  ...data
}: IGetCsvCustomRecordsRequest & { token?: string }) => {
  const promise = axios
    .get(`${import.meta.env.REACT_APP_API_URL}/records/fields_search.csv`, {
      params: {
        limit: total,
        ...data,
      },
      headers: {
        'bh-Authorization': token,
      },
    })
    .then(({ data }) => {
      return data;
    });

  toast.promise(promise, {
    error: 'The CSV export failed. Please try again.',
    pending: 'Exporting...',
  });

  const result = await promise;

  return new Blob([result], { type: 'text/csv' });
};
