import React from 'react';
import { Icon, IconifyIcon } from '@iconify/react';
import { Box, Typography, useTheme } from '@mui/material';
import { WidgetBase, WidgetBaseProps } from 'components/WidgetBase';

import { DataStatus } from 'types/enum/data-status.enum';
import { getColorByStatus } from 'utils/get-color-by-status';

interface CustomWidgetProps extends WidgetBaseProps {
  title: string;
  icon: IconifyIcon | string;
  items: {
    count: number;
    label: string;
    status: DataStatus;
  }[];
}

const CustomWidget = ({ icon, items = [], ...rest }: CustomWidgetProps) => {
  const { palette, spacing } = useTheme();

  return (
    <WidgetBase padding={spacing(0, 2)} {...rest}>
      <Icon icon={icon} width={80} height={80} color={palette.primary.main} />
      <Box
        display="grid"
        columnGap={1}
        p={1}
        gridTemplateColumns="min-content auto"
        flexGrow={1}
      >
        {items.map((item) => {
          const { textColor } = getColorByStatus(item.status, palette);
          return (
            <React.Fragment key={item.label}>
              <Typography color={textColor} fontWeight={700} textAlign="right">
                {item.count}
              </Typography>
              <Typography color={textColor}>{item.label}</Typography>
            </React.Fragment>
          );
        })}
      </Box>
    </WidgetBase>
  );
};

export default CustomWidget;
