import { styled } from '@mui/material';
import logo from '../assets/boundary-care.svg';
const CustomImage = styled('img')((props: { size: number }) => ({
  width: `${props.size}px`,
  height: `${props.size}px`,
}));

const TitleLogo: React.FC<{ size?: number }> = ({ size = 40 }) => {
  return <CustomImage src={logo} size={size} />;
};

export default TitleLogo;
