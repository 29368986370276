import { styled } from '@mui/material';
import theme from 'styles/theme';

export const Wrapper = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  minHeight: '100dvh',
  color: `${theme.palette.white.main}`,

  [theme.breakpoints.down(834)]: {
    display: 'block',
    height: '100%',
    width: '100%',
  },
}));

export const LeftContent = styled('div')(() => ({
  height: '100dvh',
  width: '50vw',
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  background:
    'linear-gradient(202.4deg, rgba(14, 52, 105, 0.88) -10.56%, rgba(43, 105, 118, 0.88) 92.1%, rgba(21, 65, 108, 0.88) 145.78%)',

  [theme.breakpoints.down(834)]: {
    display: 'none',
  },
}));

export const RightContent = styled('div')(() => ({
  gridColumn: 2,
  maxWidth: '512px',
  width: '100%',
  padding: theme.spacing(9, 4),
  background: `${theme.palette.white.main}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0 auto',
  overflowY: 'auto',
  maxHeight: '100%',
  minHeight: '100dvh',
  [theme.breakpoints.down(480)]: {
    padding: theme.spacing(5, 2),
  },
}));

export const Background = styled('div')`
  height: 100%;
  background-repeat: no-repeat;
  background-position: -70px center;
  background-size: 105%;
`;

export const Filter = styled('div')`
  height: 100%;
  background: ${theme.palette.backgroundLinear.main};
`;

export const Content = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
`;

export const Top = styled('div')`
  background-color: transparent;
`;

export const Span = styled('span')`
  color: ${theme.palette.secondary.main};
`;
