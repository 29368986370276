import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';
import { RootState } from 'store';
import { BCLocalStorage } from 'utils/storage';

export const apiAxios = axios.create();

const baseQuery: BaseQueryFn<AxiosRequestConfig> = async (
  axiosConfig,
  { getState },
) => {
  try {
    const bhKey = BCLocalStorage.hipaa
      ? import.meta.env.REACT_APP_BH_KEY_ALTERNATE
      : import.meta.env.REACT_APP_BH_KEY;
    const baseURL = BCLocalStorage.hipaa
      ? import.meta.env.REACT_APP_API_URL_ALTERNATE
      : import.meta.env.REACT_APP_API_URL;

    const token = (getState() as RootState).user.token;

    const headers: Partial<RawAxiosRequestHeaders> = {
      'Content-Type': 'application/json',
      'bh-key': bhKey,
    };

    if (token) {
      headers['bh-Authorization'] = token;
    }

    const result = await apiAxios({
      baseURL,
      ...axiosConfig,
      headers: { ...headers, ...axiosConfig.headers },
    });

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export const bcApi = createApi({
  baseQuery,
  tagTypes: [
    'DashboardData',
    'MfaCredentials',
    'Credentials',
    'PossibleRecipients',
    'AlerterSchedule',
    'DeviceData',
    'SharedRecipientSets',
    'AlerterData',
    'ProfileData',
    'SafeZones',
    'RemindersList',
    'AlertersList',
    'AlerterEventsByWidget',
    'LinkedCaregivers',
  ],
  endpoints: () => ({}),
});
