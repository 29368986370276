import { Icon } from '@iconify/react';
import { Link } from '@mui/material';
import { useMemo } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';

type Props = {
  phoneNumber: string;
};

export const ViewLineButton = ({ phoneNumber }: Props) => {
  const { formattedNumber, country = '' } = useMemo(() => {
    const parsedNumber = parsePhoneNumber(phoneNumber);

    if (!parsedNumber) {
      return {
        formattedNumber: phoneNumber,
        country: '',
      };
    }

    const formattedNumber = parsedNumber?.nationalNumber;

    return {
      country: parsedNumber?.country,
      formattedNumber:
        parsedNumber.country === 'US'
          ? `${formattedNumber.slice(0, 3)}.${formattedNumber.slice(
              3,
              6,
            )}.${formattedNumber.slice(6)}`
          : '',
    };
  }, [phoneNumber]);

  if (country !== 'US') {
    return null;
  }

  return (
    <Link
      href={`https://mb.verizonwireless.com/mbt/secure/index?appName=smgmt&navtrk=globalnav:manage:wirelessnumbersoverviewt#/wireless-numbers/line/${formattedNumber}`}
      target="_blank"
      fontSize={16}
      display="flex"
      alignItems="center"
      gap={1}
    >
      View Line Info
      <Icon icon="material-symbols:open-in-new" />
    </Link>
  );
};
