import { Box, InputBaseProps, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import Input from './Input';
interface Props {
  startProps: TextFieldProps;
  endProps: TextFieldProps;
  onClick?: () => void;
  labelPlacement?: 'top' | 'left';
  disabled?: boolean;
}

const RangeInput = forwardRef<HTMLDivElement, Props>(
  ({ startProps, endProps, onClick, labelPlacement, disabled }, ref) => {
    const { key: startPropsKey, ...startPropsRest } = startProps;
    const { key: endPropsKey, ...endPropsRest } = endProps;

    return (
      <Box
        ref={ref}
        display="flex"
        alignItems="center"
        px={labelPlacement === 'top' ? 0 : 1}
        gap={labelPlacement === 'top' ? 2 : 0}
        flexWrap="wrap"
      >
        <Input
          labelPlacement={labelPlacement}
          onClick={onClick}
          disabled={disabled}
          size="small"
          sx={{ cursor: 'pointer' }}
          key={startPropsKey}
          {...(startPropsRest as InputBaseProps)}
        />
        <Input
          labelPlacement={labelPlacement}
          onClick={onClick}
          disabled={disabled}
          size="small"
          sx={{ cursor: 'pointer' }}
          key={endPropsKey}
          {...(endPropsRest as InputBaseProps)}
        />
      </Box>
    );
  },
);

export default RangeInput;
