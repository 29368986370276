import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from 'firebase/messaging';
import { getPerformance } from 'firebase/performance';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

const event = new Event('notification');

async function makeRequest({
  accountId,
  bhToken,
}: {
  accountId: number | null;
  bhToken: string;
}) {
  const fcmToken = await getToken(messaging, {
    vapidKey:
      'BLLT3oJABkhsEdTynGaIypki3bCWh1vheWl71pez3wo3oA7HRz9Myx_GOFXpibda7LdA5pZJ3bBfK7QQ3icEOdI',
  });

  const uniqueBrowserId = await getCurrentBrowserFingerPrint();

  const payload = {
    name: 'Browser',
    model: '',
    system: navigator.userAgent,
    account_id: accountId,
    identifiers: {
      browser_unique_identifier: uniqueBrowserId,
    },
    push_configurations: [
      {
        platform: 'fcm-web',
        push_environment: 'production',
        token: fcmToken,
      },
    ],
  };

  await fetch(`${import.meta.env.REACT_APP_API_URL}/devices/synchronize`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'bh-key': import.meta.env.REACT_APP_BH_KEY,
      'bh-Authorization': bhToken,
    },
  });
}

export const registerDevice = async ({
  accountId,
  bhToken,
}: {
  accountId: number;
  bhToken: string;
}) => {
  try {
    await makeRequest({ accountId, bhToken });
  } catch (error) {
    console.error(error);
  }
};

export const deregisterDevice = async ({ bhToken }: { bhToken: string }) => {
  try {
    await makeRequest({ accountId: null, bhToken });
    await deleteToken(messaging);
  } catch (error) {
    console.error(error);
  }
};

const firebaseConfig = {
  apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async ({
  accountId,
  bhToken,
}: {
  accountId: number;
  bhToken: string;
}) => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      await registerDevice({ accountId, bhToken });
      onMessage(messaging, (payload) => {
        new Notification(payload.notification?.title ?? 'BoundaryCare', {
          body: payload.notification?.body ?? 'You have a new notification',
          icon: '/favicon.ico',
        });
        window.dispatchEvent(event);
      });
    } else {
      console.log('Notification permission denied.');
    }
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

const performance = getPerformance();
performance.dataCollectionEnabled = false;
performance.instrumentationEnabled = false;

export { messaging, setupNotifications };
