import { Comparator } from 'utils/triggers';
import { CooldownType } from '.';

export type TriggerValue = null | number | boolean | number[] | string;

export interface RawMetricRule {
  id: number;
  name?: string;
  metric_type: string;
  comparator: Comparator | string;
  value?: TriggerValue;
  enabled?: boolean;
  suppress_message?: boolean;
}

export interface MetricRule {
  id: number;
  metricType: string;
  comparator: Comparator | string;
  value?: TriggerValue;
  enabled?: boolean;
  suppressMessage?: boolean;
}

export interface CreateAlerterTriggerParams {
  metricRules: Omit<MetricRule, 'id'>[];
  cooldownType?: CooldownType;
  cooldownTime?: number | null;
  allowMultipleAlerterAssociations?: boolean;
}

export interface RawAlerterTrigger {
  id: number;
  cooldown_type: CooldownType;
  cooldown_time: number | null;
  metric_rules: RawMetricRule[];
  allow_multiple_alerter_associations: boolean;
}

export interface AlerterTrigger {
  id: number;
  cooldownType: CooldownType;
  cooldownTime: number | null;
  metricRules: MetricRule[];
  allowMultipleAlerterAssociations: boolean;
}

export interface RawAlerterTriggerResponse {
  result: RawAlerterTrigger;
}

export interface AlerterTriggerUpdateParams {
  metricRule: Partial<Omit<MetricRule, 'id'>>;
  metricRuleId: number;
}

export interface AlerterTriggerDeleteParams {
  metricRuleId: number;
  triggerId: number;
}

export interface RawAlerterRuleResponse {
  result: RawMetricRule;
}
