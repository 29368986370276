import { Button } from '@mui/material';
import { Box } from '@mui/material';

const SetupMessage = ({ handleNextStep }: { handleNextStep: () => void }) => {
  return (
    <Box m={1}>
      <Button fullWidth size="large" onClick={handleNextStep}>
        Got it
      </Button>
    </Box>
  );
};

export default SetupMessage;
