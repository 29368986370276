import axios from 'axios';
import { toast } from 'react-toastify';
import { SearchAlerterEventsParams } from 'types/alerters';

export const getAlertsHistoryCsv = async ({
  token,
  profileId,
  alerterIds,
  widgets,
  beginsAt,
  endsAt,
}: SearchAlerterEventsParams & { token?: string }) => {
  const promise = axios
    .get(`${import.meta.env.REACT_APP_API_URL}/alerter_events/search.csv`, {
      params: {
        patient_profile_id: profileId,
        alerter_ids: alerterIds,
        widgets,
        begins_at: beginsAt,
        ends_at: endsAt,
      },
      headers: {
        'bh-Authorization': token,
      },
    })
    .then(({ data }) => {
      return data;
    });

  toast.promise(promise, {
    error: 'The CSV export failed. Please try again.',
    pending: 'Exporting...',
  });

  const result = await promise;

  return new Blob([result], { type: 'text/csv' });
};
