/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
// import * as geom from 'jsts/org/locationtech/jts/geom';
// // @ts-ignore
// import * as operation from 'jsts/org/locationtech/jts/operation';

export const initMap = (region?: mapkit.CoordinateRegion) => {
  const tokenID = import.meta.env.REACT_APP_IOS_MAP_KEY;

  mapkit.init({
    authorizationCallback: (done) => {
      done(tokenID);
    },
  });

  const map = new mapkit.Map('map', {
    region,
  });

  // hide the compass.
  map.showsCompass = mapkit.FeatureVisibility.Hidden;

  return map;
};

export const calculatePolygonCentroid = (
  points: [number, number][],
): { latitude: number; longitude: number } | null => {
  const numPoints = points.length;

  if (numPoints < 3) {
    // A polygon must have at least 3 points
    return null;
  }

  let xSum = 0;
  let ySum = 0;
  let area = 0;

  for (let i = 0; i < numPoints; i++) {
    const currentPoint = points[i];
    const nextPoint = points[(i + 1) % numPoints]; // Circular index for the last point

    const x0 = currentPoint[0];
    const y0 = currentPoint[1];
    const x1 = nextPoint[0];
    const y1 = nextPoint[1];

    const commonFactor = x0 * y1 - x1 * y0;

    xSum += (x0 + x1) * commonFactor;
    ySum += (y0 + y1) * commonFactor;
    area += commonFactor;
  }

  area *= 0.5;

  if (area === 0) {
    return null;
  }

  const cx = xSum / (6 * area);
  const cy = ySum / (6 * area);

  return { latitude: cx, longitude: cy };
};

export const calculatePathCentroid = (
  points: {
    latitude: number;
    longitude: number;
  }[],
) => {
  if (points.length === 0) {
    return null;
  }

  let sumX = 0;
  let sumY = 0;

  for (const point of points) {
    sumX += point.latitude;
    sumY += point.longitude;
  }

  const centroidX = sumX / points.length;
  const centroidY = sumY / points.length;

  return { latitude: centroidX, longitude: centroidY };
};

interface Coordinate {
  latitude: number;
  longitude: number;
}

export function hasSelfIntersection(points: Coordinate[]) {
  const polygon: [number, number][] = points.map(({ latitude, longitude }) => [
    latitude,
    longitude,
  ]);

  for (let i = 0; i < polygon.length - 1; i++) {
    const edge1Start = polygon[i];
    const edge1End = polygon[i + 1];

    for (let j = i + 2; j < polygon.length - 1; j++) {
      const edge2Start = polygon[j];
      const edge2End = polygon[j + 1];

      if (doEdgesIntersect(edge1Start, edge1End, edge2Start, edge2End)) {
        return true; // Self-intersection found
      }
    }
  }
  return false; // No self-intersection found
}

// Helper function to check if two line segments intersect
function doEdgesIntersect(
  a: [number, number],
  b: [number, number],
  c: [number, number],
  d: [number, number],
): boolean {
  function ccw(
    p1: [number, number],
    p2: [number, number],
    p3: [number, number],
  ): boolean {
    return (
      (p3[1] - p1[1]) * (p2[0] - p1[0]) > (p2[1] - p1[1]) * (p3[0] - p1[0])
    );
  }

  if (a[0] === d[0] && a[1] === d[1]) return false;

  return ccw(a, c, d) !== ccw(b, c, d) && ccw(a, b, c) !== ccw(a, b, d);
}

const calculateDistance = (point1: Coordinate, point2: Coordinate): number => {
  const lat1 = point1.latitude;
  const lon1 = point1.longitude;
  const lat2 = point2.latitude;
  const lon2 = point2.longitude;

  const R = 6371; // Earth's radius in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

const findClosestPointIndex = (
  points: Coordinate[],
  targetPoint: Coordinate,
): number => {
  let closestIndex = -1;
  let closestDistance = Number.MAX_VALUE;

  for (let i = 0; i < points.length; i++) {
    const distance = calculateDistance(points[i], targetPoint);
    if (distance < closestDistance) {
      closestIndex = i;
      closestDistance = distance;
    }
  }

  return closestIndex;
};

export const addPointAndCreateNewPolygon = (
  existingPoints: Coordinate[],
  newPoint: Coordinate,
): Coordinate[] => {
  const closestIndex = findClosestPointIndex(existingPoints, newPoint);

  if (closestIndex === -1) {
    return [newPoint];
  }

  const nextIndex = (closestIndex + 1) % existingPoints.length;
  const newPolygon = [...existingPoints];

  newPolygon.splice(nextIndex, 0, newPoint);

  return newPolygon;
};
