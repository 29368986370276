type DayOfTheWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface SerializedSchedule {
  startTime: number | null;
  endTime: number | null;
  days: DayOfTheWeek[];
}

export interface RawSerializedSchedule {
  start_time: number | null;
  end_time: number | null;
  days_of_week: DayOfTheWeek[];
}

export interface RawAlerterScheduler {
  initial_delay: number;
  allow_multiple_alerter_associations: boolean;
  invert_time_range: boolean;
  serialized_schedule: RawSerializedSchedule;
}

export interface AlerterScheduler {
  initialDelay: number;
  allowMultipleAlerterAssociations: boolean;
  invertTimeRange: boolean;
  serializedSchedule: SerializedSchedule;
}

export interface AlerterSchedulerResponse {
  result: RawAlerterScheduler & { id: number };
}

export type LinkAlerterSchedulerParams =
  | {
      id: number;
      alerterId?: number;
      alerterRecipientManagerId?: number;
    } & (
      | {
          id: number;
          alerterId: number;
        }
      | {
          id: number;
          alerterRecipientManagerId: number;
        }
    );
