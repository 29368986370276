import { wristTemperature } from 'assets/iconify/wrist-temperature';
import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';

export const getWristTemperatureStatus = (
  value?: number | null,
): DataCellProps & { value?: string | number } => {
  const roundedValue = value ? value.toFixed(1) : value;

  if (roundedValue) {
    return {
      icon: wristTemperature.icons.normal,
      status: DataStatus.POSITIVE,
      label: 'Normal',
      value: `${roundedValue}°F`,
    };
  }

  if (roundedValue === null) {
    return {
      icon: wristTemperature.icons.noData,
      status: 'no data',
      label: 'No data',
      value: 'N/A',
    };
  }

  return {
    icon: wristTemperature.icons.abnormal,
    status: DataStatus.NEGATIVE,
    label: 'Disabled',
    value: 'N/A',
  };
};
