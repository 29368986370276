import { Icons } from 'assets/icons';
import { Timestamp } from 'types/date-time';
import type { DataTableColumn } from 'types/dashboard';
import { PatientRecord } from 'types/patient';
import { BatteryState } from 'types/device';
import { AlerterEventsWidgets, SourceType } from 'types/alerters';

export type IDashboardRequest = { account_id: number };

export interface IAcknowledgeRequest {
  id: number;
  includeHighHeartRate?: boolean;
  includeLowHeartRate?: boolean;
  includeIrregularHeartRate?: boolean;
  includeFallDetection?: boolean;
  includeSafeZone?: boolean;
}

export type ILocationRecordDataPrimary = {
  lat: number;
  lng: number;
  speed: number;
  course: number;
  accuracy: number;
  timestamp: string;
};

export type ILocationRecordDataSystem = {
  lat: number;
  lng: number;
  speed: number;
  course: number;
  accuracy: number;
  timestamp: string;
};

export type ILocationRecordData = {
  primary_location: ILocationRecordDataPrimary;
  system_locations: ILocationRecordDataSystem[];
  bad_location_latitude: null;
};

export type ILocationRecord = {
  id: number;
  account_id: number;
  device_id: number;
  record_data: ILocationRecordData | null;
};

export type IDeviceInfoRecordDataLocationPermissions = {
  usage_authorization: string;
  accuracy_authorization: string;
};

export type IDeviceInfoRecordDataMotionPermissions = {
  accelerometer_available: boolean;
  accelerometer_authorization: string;
};

export type IDeviceInfoRecordDataNotificationPermissions = {
  alert_setting: string;
  sound_setting: string;
  pushkit_active: boolean;
  critical_setting: string;
  scheduled_setting: string;
  notifications_active: boolean;
  time_sensitive_setting: string;
  notification_center_setting: string;
  visible_notifications_authorization: string;
};

export type IDeviceInfoRecordDataFallDetectionPermissions = {
  fall_detection_available: boolean;
  fall_detection_authorization: string;
};

export type IDeviceInfoRecordData = {
  trigger: string;
  device_name: string;
  permissions: {
    location_permissions: IDeviceInfoRecordDataLocationPermissions;
    motion_data_permissions: IDeviceInfoRecordDataMotionPermissions;
    notifications_permissions: IDeviceInfoRecordDataNotificationPermissions;
    fall_detection_permissions: IDeviceInfoRecordDataFallDetectionPermissions;
  };
  battery_level: number;
  battery_state: BatteryState;
  device_locked: boolean;
  running_state: string;
  water_lock_enabled: boolean;
  active_complication: boolean;
  has_valid_auth_token: boolean;
  background_networking: boolean;
  workout_session_state: string;
  cell_network_information: {
    last_interfaces: string[];
  };
  has_valid_notification_token: boolean;
  extended_runtime_session_state?: null | number;
};

export type IDeviceInfoRecord = {
  id: number;
  account_id: number;
  device_id: number;
  record_data: IDeviceInfoRecordData;
};

export type ISettingsRecordDataLastHealthAcks = {
  fall_detection?: string;
  low_heart_rate_event?: string;
  high_heart_rate_event?: string;
  irregular_heart_rate_event?: string;
};

export type ISettingsRecordData = {
  lo_in_zone: boolean;
  account_name: string;
  last_poll_at: string;
  device_active: boolean;
  configuration_ok: boolean;
  last_health_acks: ISettingsRecordDataLastHealthAcks;
  any_device_active: boolean;
  account_last_poll_at: string;
  device_last_disconnected_at: string;
  device_has_bad_location_latitude: boolean;
  account_has_active_fall_subscriptions: boolean;
  account_has_active_compliance_subscriptions: boolean;
};

export type ISettingsRecord = {
  id: number;
  account_id: number;
  device_id: number;
  record_data: ISettingsRecordData;
};

export interface IHealthRecordDataEvent {
  capable: boolean;
  authorized: boolean;
  value: number | null;
  start_date: string;
  end_date: string;
}

export interface IDailySleepEvent
  extends Omit<IHealthRecordDataEvent, 'value'> {
  value: string | null;
}

export type IHealthRecordDataHighLowHeartEvent = {
  capable: boolean;
  authorized: boolean;
  value: null | number;
  string_value: null | number;
  start_date: string;
  end_date: string;
};

export type IHealthRecordData = {
  formatted_health_data?: {
    heart_rate: IHealthRecordDataEvent;
    high_heart_rate_event: IHealthRecordDataHighLowHeartEvent;
    low_heart_rate_event: IHealthRecordDataHighLowHeartEvent;
    irregular_heart_rate_event: IHealthRecordDataEvent;
    afib_burden?: IHealthRecordDataEvent;
    fall_detection: IHealthRecordDataEvent;
    respiration: IHealthRecordDataEvent;
    blood_oxygen: IHealthRecordDataEvent;
    walk_steadiness_score: IHealthRecordDataEvent;
    walk_steadiness_event: IHealthRecordDataEvent;
    daily_step_counts: IHealthRecordDataEvent;
    daily_sleep: IDailySleepEvent;
    daily_total_energy_burn: IHealthRecordDataEvent;
    cardio_fitness: IHealthRecordDataEvent;
    wrist_temperature: IHealthRecordDataEvent;
  };
};

export type IHealthRecord = {
  id: number;
  account_id: number;
  device_id: number;
  record_data: IHealthRecordData;
};

export type IActivityRecordData = {
  distance: number;
  end_date: string;
  start_date: string;
  step_count: number;
  current_pace: null;
  current_cadence: null;
  floors_ascended: number;
  floors_descended: number;
  average_active_pace: number;
};

export type IActivityRecord = {
  id: number;
  account_id: number;
  device_id: number;
  record_data: {
    pedometer_data: IActivityRecordData;
    pedometer_data_collected_at: string;
  };
};

export type IUsageRecordDataAnalyzedComplianceStatus = {
  status: string;
  end_date: string;
  start_date: string;
};

export const WornStatus = {
  WORN: 'worn',
  NOT_WORN: 'not_worn',
} as const;

export type WornStatus = (typeof WornStatus)[keyof typeof WornStatus];

export type IUsageRecordDataMostRecentDeviceWornStatus = {
  status: WornStatus;
  end_date: string;
  start_date: string;
};

export type IUsageRecordData = {
  end_date?: string;
  start_date?: string;
  collected_at?: string;
  analyzed_compliance_status?: IUsageRecordDataAnalyzedComplianceStatus[];
  most_recent_device_worn_status?: IUsageRecordDataMostRecentDeviceWornStatus;
};

export type IUsageRecord = {
  id: number;
  account_id: number;
  device_id: number;
  record_data: IUsageRecordData;
};

export type IDashboardResult = {
  id: number;
  account_id: number;
  device_id: number;
  created_at: string;
  generated_at: string;
  source_format: string;
  source_format_version: string;
  source_uuid: string;
  synthesized: boolean;
  location_record: ILocationRecord | null;
  device_info_record: IDeviceInfoRecord;
  settings_record: ISettingsRecord;
  health_record: IHealthRecord;
  activity_record: IActivityRecord;
  usage_record: IUsageRecord;
};

export type HelpRequestEventData = {
  id: number;
  account_id: number;
  account_type: SourceType;
  reporting_account_id: number;
  reporting_account_type: SourceType;
  event_type: 'help-requested';
  start_date: string;
  end_date: string;
  additional_description: null | string;
};

export type SeizureEventData = {
  id: number;
  account_id: number;
  account_type: SourceType;
  reporting_account_id: number;
  reporting_account_type: SourceType;
  event_type: 'tonic-clonic-seizure';
  start_date: string;
  end_date: string;
  additional_description: string;
};

export type IDashboardResponse = {
  result: IDashboardResult | null;
  meta: {
    last_help_requested_event: null | HelpRequestEventData;
    last_tonic_clonic_seizure_event: null | SeizureEventData;
    alerter_targeted_widgets: AlerterEventsWidgets[];
  };
};

export type IWidgetStatus<T> = {
  icon: Icons;
  status: T;
  color: string;
  value?: number;
  capable?: boolean;
};

export interface IGetCustomRecordsRequest {
  patient_profile_id: number;
  device_id: number;
  display_columns: DataTableColumn[];
  begins_at: Timestamp;
  ends_at: Timestamp;
  offset: number;
  limit: number;
}

export interface IGetCsvCustomRecordsRequest {
  patient_profile_id: number;
  device_id: number;
  display_columns: DataTableColumn[];
  begins_at: Timestamp;
  ends_at: Timestamp;
  total: number;
}

export interface CustomRecord {
  timestamp: Timestamp;
  lo_in_zone?: boolean | null;
  configuration_ok?: boolean | null;
  any_device_active?: boolean | null;
  device_locked?: boolean | null;
  device_worn?: WornStatus | null;
  battery_state?: BatteryState | null;
  battery_level?: string | null;
  heart_rate?: string | null;
  high_heart_rate_event?: string | null;
  low_heart_rate_event?: string | null;
  irregular_heart_rhythm_event?: string | null;
  blood_oxygen_level?: string | null;
  sleep_analysis?: string | null;
  fall?: string | null;
  lat?: string | null;
  lng?: string | null;
}

export interface IGetCustomRecordsResponse {
  results: CustomRecord[];
  meta: {
    total_rows: number;
    total_displayed_rows: number;
  };
}

export interface IGetCustomRecordsTransformedResponse {
  rows: PatientRecord[];
  totalNumber: number;
  displayedNumber: number;
}
