import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const respiratoryRate: IconifyJSON = {
  prefix: 'respiratory-rate',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M5.25 20.75C4.48333 20.75 3.83333 20.4833 3.3 19.95C2.76667 19.4167 2.5 18.7667 2.5 18V13.875L5.025 7.125C5.24167 6.575 5.59167 6.125 6.075 5.775C6.55833 5.425 7.09167 5.25 7.675 5.25C8.19167 5.25 8.65833 5.39167 9.075 5.675C9.49167 5.95833 9.775 6.33333 9.925 6.8V9.175L7.425 11.675L8.35 12.6L11.25 9.7V2.25H12.75V9.7L15.65 12.6L16.55 11.675L14.05 9.175V6.8C14.2167 6.33333 14.5043 5.95833 14.913 5.675C15.321 5.39167 15.7833 5.25 16.3 5.25C16.8833 5.25 17.4167 5.425 17.9 5.775C18.3833 6.125 18.7333 6.575 18.95 7.125L21.475 13.875V18C21.475 18.7667 21.2083 19.4167 20.675 19.95C20.1417 20.4833 19.4917 20.75 18.725 20.75H15.725C14.975 20.75 14.3333 20.4833 13.8 19.95C13.2667 19.4167 13 18.7667 13 18V16L13.375 12.475L12 11.075L10.575 12.475L11 16V18C11 18.7667 10.7293 19.4167 10.188 19.95C9.646 20.4833 9 20.75 8.25 20.75H5.25Z" fill="currentColor"/>',
    },
  },
};
